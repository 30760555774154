<template>
    <div :id="id" class="qrcode"></div>
</template>
<script>
    import QRCode from 'qrcodejs2'
    export default {
        name: 'qrcode',
        props: {
            url: {
                type: String,
                default: ''
            },
            id: {
                type: String,
                default: ''
            }
        },
        data() {
            return {}
        },
        methods: {
            qrcode() {
                this.$nextTick(() => {
                    document.getElementById(this.id).innerHTML = ''
                    let qrcode = new QRCode(this.id, {
                        text: this.url,
                        width: 110,
                        height: 110
                    })
                })
            }
        },
        mounted() {
            this.qrcode()
        }
    }
</script>
<style scoped lang="less">
    .qrcode {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
