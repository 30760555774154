<template>
    <div class="pd20x flex1">
        <addSection :visible="visible" @close="visible = false" :edit="editItem" :data="currentItem" @update="getData"></addSection>
        <div class="h100pct ofA">
            <div class="filter pd20x mb10x">
                <div>学科名称 : {{name}}</div>
                <div>
                    <a-button type="primary" @click="()=>{ currentItem = {subjectid:classId} ; editItem= false ;visible = true }">添加</a-button>
                </div>
            </div>
            <a-spin :spinning="loading">
                <div class="dragTable">
                    <div class="dragHeader">
                        <div class="iconTh"></div>
                        <div class="flex1">小节名称</div>
                        <div class="flex1">二维码</div>
                        <div class="flex1">类型</div>
                        <div class="flex1">链接</div>
                        <div class="flex1">操作</div>
                    </div>
                    <draggable handle=".dragIcon" :list="result" v-if="result.length" @change="log">
                        <div class="dragBody" v-for="(item, index) in result" :key="index">
                            <div class="iconTh">
                                <i class="dragIcon"></i>
                            </div>
                            <div class="flex1">
                                {{item.name}}
                            </div>
                            <div class="flex1">
                                <qRCode :url="'http://st.shitankeji.com/papers/videos?id='+classId+'&videoId='+item.id" :id="'qrcode'+item.id"></qRCode>
                            </div>
                            <div class="flex1">
                                <span v-if="item.type == 1">视频</span>
                                <span v-if="item.type == 2">PDF文件</span>
                            </div>
                            <div class="flex1">
                                <a :href="item.material" target="_blank">{{item.material}}</a>
                            </div>
                            <div class="flex1">
                                <a-button type="primary" style="margin-right:10px" @click="download('qrcode'+item.id,item.name)">下载</a-button>
                                <a-button type="primary" style="margin-right:10px" @click="()=>{currentItem = item ;editItem =true; visible = true}">修改</a-button>
                                <a-button type="primary" ghost style="margin-right:10px" @click="deleteItem(item.id)">删除</a-button>
                            </div>
                        </div>
                    </draggable>
                    <div id="qrcode"></div>
                    <div v-if="!result.length && !loading" style="padding:20px 0;text-align:center;background:#fff">
                        暂无数据
                    </div>
                </div>
            </a-spin>
        </div>
    </div>
</template>
<script>
    import * as http from '@/libs/analysis'
    import addSection from '@/components/analysis/addSection'
    import qRCode from '@/components/analysis/qRCode'
    export default {
        name: 'unitList',
        components: { addSection ,qRCode},
        data(){
            return{
                result: [],
                visible: false,
                editItem: false,
                loading: true,
                currentItem: null,
                name: '',
                classId : ''
            }
        },
        mounted(){
            if (this.$route.query.class)
                this.classId = this.$route.query.class;
            if (this.$route.query.name)
                this.name = this.$route.query.name;
            this.$nextTick(()=>{
                this.getData()
            })
        },
        methods:{
            download(id,name){
                let myCanvas = document.getElementById(id).getElementsByTagName('canvas');
                let img=document.getElementById(id).getElementsByTagName('img');
                // 创建一个a节点
                let a = document.createElement('a');
                // 设置a的href属性将canvas变成png格式
                let imgURL=myCanvas[0].toDataURL('image/jpg');
                let ua = navigator.userAgent;
                if (ua.indexOf("Trident") != -1 && ua.indexOf("Windows") != -1) { // IE内核 并且  windows系统 情况下 才执行;
                    var bstr = atob(imgURL.split(',')[1])
                    var n = bstr.length
                    var u8arr = new Uint8Array(n)
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n)
                    }
                    var blob = new Blob([u8arr])
                    window.navigator.msSaveOrOpenBlob(blob, '下载' + '.' + 'png')
                }else if(ua.indexOf("Firefox") > -1){ //火狐兼容下载
                    let blob = this.base64ToBlob(imgURL); //new Blob([content]);
                    let evt = document.createEvent("HTMLEvents");
                    evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
                    a.download = ' ';//下载图片名称，如果填内容识别不到，下载为未知文件，所以我这里就不填为空
                    a.href = URL.createObjectURL(blob);
                    a.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));//兼容火狐
                }else{ //谷歌兼容下载
                    img.src=myCanvas[0].toDataURL('image/jpg');
                    // a.href = myCanvas[0].toDataURL('image/png').replace('image/png', 'image/octet-stream')
                    a.href=img.src
                    //设置下载文件的名字
                    a.download = "下载"
                    //点击
                    a.click()
                }
            },
            getData(){
                this.loading = true
                http.getSection({id:this.classId}).then(res=>{
                    this.loading = false
                    this.result = res
                    this.qrcode()
                }).catch(error=>{
                    this.loading = false
                    console.log(error)
                })
            },
            handleChange(){

            },
            onSelectChange(){

            },
            addItem(){
                this.visible = true;
                this.currentItem = null
            },
            deleteItem(id){
                let _this = this
                this.$confirm({
                    title: '是否确定删除小节?',
                    centered: true,
                    onOk() {
                        http.delSection({id}).then(res=>{
                            _this.getData()
                        }).catch(error=>{
                            console.log(error)
                        })
                    },
                });
            },
            log(){
                let result = { ids:this.result.map(el=>el.id).join(',')};
                http.orderSection(result).then(res=>{

                }).catch(error=>{
                    this.$message.error('拖拽失败，请重新操作');
                    this.getData();
                    console.log(error)
                })
            },
            hideModal(){

            }
        }
    }
</script>
<style lang="less" scoped>

</style>
