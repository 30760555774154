<template>
    <a-drawer
            :title="title"
            :visible="visible"
            :placement="'top'"
            @close="$emit('close')"
            :height="'100%'"
            :wrapClassName="'addClasses'"
    >
        <div class="content">
            <a-form
                    :form="form"
                    v-bind="formItemLayout"
            >
                <a-form-item label="小节标题">
                    <a-input
                            v-decorator="['name', { initialValue: formData.name, rules: [{ required: true, message: '请输入小节标题' }] }]"
                    />
                </a-form-item>
                <a-form-item label="类型">
                    <a-select v-decorator="['type', { initialValue: formData.type, rules: [{ required: true, message: '请选择类型' }] }]" @change="changeCate" class="mr10px" placeholder="请选择类型">
                        <a-select-option v-for="item in options" :key="item.id" :value="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="上传视频" v-if="this.form.getFieldValue('type') == 1 " >
                    <div style="display:flex;align-items:center">
                        <a-upload
                                name="file"
                                list-type="picture"
                                :customRequest="customRequest"
                                @change="handleChange"
                                :fileList="videoList"
                        >
                            <a-button> <a-icon type="upload" />上传视频</a-button>
                        </a-upload>
                    </div>
                </a-form-item>
                <a-form-item label="上传PDF文件" v-else>
                    <div style="display:flex;align-items:center">
                        <a-upload
                                name="file"
                                :accept="'.PDF'"
                                :customRequest="customRequest"
                                @change="handleChange"
                                :fileList="pdfList"
                        >
                            <a-button> <a-icon type="upload" />上传PDF</a-button>
                        </a-upload>
                    </div>
                </a-form-item>
                <a-form-item label="小节资料">
                    <a-input
                            v-decorator="['material', { initialValue: formData.material, rules: [{ required: true, message: '小节资料' }] }]"
                    />
                </a-form-item>
            </a-form>
            <div style="text-align:right;padding-top:30px;padding-right:20%">
                <a-button class="mr20px" style="width:100px" @click="$emit('close')">取消</a-button>
                <a-button type="primary"  style="width:100px" @click="submit">立即提交</a-button>
            </div>
        </div>
    </a-drawer>
</template>
<script>
    import { uploadImg } from '@/libs/api'
    import * as http from '@/libs/analysis'
    export default {
        name: 'addSection',
        props: ['visible' , 'data', 'edit', 'allSubject'],
        beforeCreate() {
            this.form = this.$form.createForm(this);
        },
        data(){
            return{
                options :[
                    {
                        name: '视 频',
                        id:1
                    },{
                        name:'文 件',
                        id:2
                    }
                ],
                formItemLayout: {
                    labelCol: { span: 8 },
                    wrapperCol: { span: 10 },
                },
                videoList : [],
                pdfList : [],
                filter : {},
                detail_type: 1,
                showP:false,
                loading: false,
                subjectList: [],
                ProfessList:[],
                detailUrl:'',
                title:'小节内容',
                showSchool: false,
                showBpid: false,
                SchoolList: [],
                BpidList: [],
                detailfileImg:[],
                detail_bg_url:'',
                formData:{
                    subjectid    : '',
                    title        : '',
                    type         : 1,
                    material     : ''
                }
            }
        },
        watch:{
            visible(val){
                if(!val){
                    this.reset()
                }else{
                    this.init().then(()=>{
                        if(this.edit){
                            Object.assign(this.formData, this.data);
                            this.title = '编辑小节内容'
                            setTimeout(()=>{
                                this.form.setFieldsValue({'type':this.data.type})
                            })
                        }else{
                            this.title = '新增小节内容'
                            this.formData.subjectid = this.data.subjectid
                        }
                    })
                }
            }
        },
        methods:{
            handleChange(info){
                let fileList = [...info.fileList];
                fileList = fileList.slice(-1);
                fileList = fileList.map(file => {
                    if (file.response) {
                        file.url = file.response.url;
                    }
                    return file;
                });
                this.fileList = fileList;
            },
            reset(){
                this.form.resetFields()
                this.loading = false
                this.detailUrl = ''
                this.formData = {
                    subjectid    : '',
                    title    : '',
                    type     : 1,
                    material : ''
                }
            },
            init(){
                return new Promise((resolve,reject) => {
                    Promise.all([]).then(res=>{
                        resolve()
                    }).catch(reject)
                })
            },
            changeCate(){
                setTimeout(()=>{
                    if(this.form.getFieldValue('type')) this.formData.type = this.form.getFieldValue('type');
                    //this.subjectList = this.allSubject.filter(el=>el.cate_id == this.form.getFieldValue('cate_id'))
                })
            },
            customRequest(data){
                let formData = new FormData()
                formData.append('file',data.file)
                uploadImg(formData).then(res=>{
                    if(res){
                        this.$message.info('上传完成');
                        this.formData.material = res.fileUrl;
                    }
                },error=>{
                    console.log(error)
                })
            },
            submit(){
                this.form.validateFields((err, values) => {
                    if(err) return;
                    let request = this._.cloneDeep(values)
                    request.subjectid     = this.formData.subjectid
                    request.type          = this.formData.type
                    // request.material      = this.formData.material
                    if(this.edit){
                        request.id = this.data.id
                        http.editSection(request).then(res=>{
                            this.$emit('close')
                            this.$emit('update')
                        }).catch(error=>{
                            console.log(error)
                        })
                    }else{
                        http.addSection(request).then(res=>{
                            this.$emit('close')
                            this.$emit('update')
                        }).catch(error=>{
                            console.log(error)
                        })
                    }
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .sTable{
        width: 420px;
        border: 1px solid #eee;
        .th{
            background: #eeeeee;
            height: 32px;
            display: flex;
            align-items: center;
            text-align: center;
            >div{
                flex: 1;
            }
        }
        .tbody{
            padding: 20px 10px;
            display: flex;
            align-items: center;
            >div{
                flex: 1;
            }
        }
    }
</style>
